<script>
import RsNonConformityMixin from "@/mixins/RsNonConformityMixin";
import attachmentMixin from "@/mixins/attachmentMixin";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import RsAttachment from "@/components/modules/rightship/RsAttachment.vue";
import RsImageComponent from "@/components/modules/rightship/RsImageComponent.vue";

export default {
  name: "NonConformityCorrectiveAction",
  components: {RsAttachment,RsImageComponent},
  mixins:[RsNonConformityMixin,attachmentMixin],
  data() {
    return {
      toolbar: [
        ['bold', 'italic', 'strike', 'underline'],
        [{
          'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]
        }]
      ],
      addRsncBeforeAttachment:false,
      addRsncInstructionAttachment:false,
      addRsncRectifiedPhoto:false,
      addRsncRectifiedAttachments:false,
      addAttachment:false,
      editStatus:false,
      reportStatusInitial:[
          {
            id:1,
            name:'In Progress'
          },
          {
            id:2,
            name:'Ready to Close'
          },
          {
            id:3,
            name:'Closed'
          },
          {
            id:4,
            name:'Withdraw'
          },
      ]
    }
  },

  computed:{
    ...mapGetters(['rsncBeforeRecAttachments','rsncInstructionAttachments','rsncRectifiedPhotoAttachments','rsncRectifiedAttachments']),
  },

  methods:{
    ...mapActions(['uploadRsAttachments','updateRsNonConformity']),
    setCorrectiveActionOption() {
      if(!this.nonConformityField.corrective_action.is_corrective_action_effective){
        this.nonConformityField.corrective_action.is_corrective_action_verified=0;
        this.nonConformityField.corrective_action.is_corrective_action_verified_date=null;
        this.nonConformityField.corrective_action.is_corrective_action_confirmed=0;
        this.nonConformityField.corrective_action.is_corrective_action_confirmed_date=null;
      }
    },
    async addRsncCorrectiveActionAttachments(type,buttonState='addRsncBeforeAttachment'){
      let attachmentField='';
      switch(type){
        case 2:
          attachmentField='rsncBeforeAttachment'
              break;
        case 3:
          attachmentField='rsncInstructionAttachment'
              break;
        case 4:
          attachmentField='rsncRectifiedPhoto'
              break;
        case 5:
          attachmentField='rsncRectifiedAttachments'
              break;
        default:
          attachmentField='rsncBeforeAttachment'
            break;
      }
      let files= this.$refs[attachmentField].files;
      const formData=new FormData();
      if(files.length > 0) {
        Array.prototype.forEach.call(files,file=>{
          formData.append('attachments[]',file)
        })
        formData.append('id', this.$route.params.id)
        formData.append('type[id]', this.nonConformity.id)
        formData.append('type[type_id]', type)
        const response = await this.uploadRsAttachments(formData);
        if(response){
          AlertService.successAlert('Attachment added successfully','Add Corrective Action Attachments')
          await this.getRsAttachment(type,this.nonConformity,'id')
          this.$refs[attachmentField].value=null;
          this[buttonState] = false;
        }
      }
    },

    async deleteCorrectiveAttachment(event) {
      const response = await this.deleteFileRs(event.attachment, event.type)
      if(response) {
        AlertService.successAlert('Attachment Deleted Successfully','Delete Corrective Action Attachment')
        await this.getRsAttachment(event.type,this.nonConformity,'id')
      }
    },

    editReportStatus() {
      this.editStatus = true;
      this.nonConformityField=Object.assign({},this.nonConformity)
      this.currentStatus=this.nonConformityField?.report_status_id ?? null
    },

    cancelEditReportStatus() {
      this.editStatus = false;
      this.resetNonConformity()
    },

    async saveStatus() {
      let currStatusName = this.currentStatus ? this.reportStatusInitial.filter(stat => stat.id === this.currentStatus)[0]?.name : null;
      const changeReportStatusName = this.reportStatusInitial.filter(stat => stat.id === this.nonConformityField.report_status_id)[0].name;
      currStatusName = !currStatusName ? '' : currStatusName;
      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })

      if ((await verify).isConfirmed) {
        const response = await this.updateRsNonConformity(this.nonConformityField);
        if (response) {
          this.editStatus=false;
          AlertService.successAlert('RS Non Conformity updated successfully', 'UPDATE RS NON CONFORMITY REPORT STATUS')
          this.resetNonConformity();
        }
      }
    },
  },

}
</script>

<template>
  <form @submit.prevent="confirmUpdateRsNonConformity">
    <div class="row">
      <div class="col-12 text-right">
        <template v-if="editNonConformity">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="setNonConformityEdit()" v-if="editNonConformity">Cancel</button>
          <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
        </template>
        <template v-else>
          <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="setNonConformityEdit(true)">Edit Corrective Action</button>
        </template>
      </div>
    </div>
    <table class="w-100">
      <tr>
        <td style="width: 25%">Questioner Section</td>
        <td>
          {{nonConformity.rsn_question.parent.main_question}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question No.</td>
        <td>
          {{nonConformity.rsn_question.code}}
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Question</td>
        <td>
          {{nonConformity.rsn_question.main_question}}
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <td>
          Report Status
        </td>
        <td>
          <template v-if="editStatus">
            <div class="row align-content-center align-items-center">
              <div class="col-auto">
                <select class="form-control form-control-sm" v-model="nonConformityField.report_status_id">
                  <template v-for="reportStatus in reportStatusInitial">
                    <option :value="reportStatus.id">{{reportStatus.name}}</option>
                  </template>
                </select>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-success btn-xs mr-2" @click="saveStatus">
                  <font-awesome-icon icon="save" class="force-white-all" />
                  Save
                </button>
                <button type="button" class="btn btn-danger btn-xs" @click="cancelEditReportStatus">
                  <font-awesome-icon icon="ban" class="force-white-all" />
                  Cancel
                </button>
              </div>
            </div>
          </template>
          <button v-else type="button" class="e-btn e-btn-xs px-5 bg-056cb4" @click="editReportStatus">
            {{nonConformity.report_status_id ? reportStatusInitial.filter(stat=>stat.id == nonConformity.report_status_id )[0].name : 'NEW'}}
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td>
          Attached Photo before rectified
        </td>
        <td>
          <template v-if="editNonConformity">
            <template v-if="!addRsncBeforeAttachment">
              <div class="mb-3">
                <button class="e-btn e-btn-green mr-3"
                        @click="addRsncBeforeAttachment=!addRsncBeforeAttachment"
                >
                  Add Attachment
                </button>
                <button class="e-btn e-btn-blue">
                  Download All
                </button>
              </div>
            </template>
            <div v-else>
              <input type="file" class="w-75" @change="addRsncCorrectiveActionAttachments(2,'addRsncBeforeAttachment')"
                     ref="rsncBeforeAttachment" accept="image/*" multiple>
              <button class="e-btn e-btn-outlined-red">Cancel</button>
            </div>
          </template>
          <div class="row">
            <div   v-if="rsncBeforeRecAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                   v-for="attachment in rsncBeforeRecAttachments">
              <rs-image-component :image-info="attachment"
                                  :type="2"
                                  @deleteEmitter="deleteCorrectiveAttachment"
                                  view-permission="psc.main.deficiency.overview.attachments-view-download"
                                  download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                  delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
              >
              </rs-image-component>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">a) Root Cause</td>
        <td>{{nonConformity.root_cause_id ? nonConformity.root_cause.name.toUpperCase() : ''}}</td>
      </tr>
      <tr>
        <td style="width: 25%">b) Factor of Root Cause</td>
        <td>{{nonConformity.root_cause_sub_id ? nonConformity.root_cause_sub.name.toUpperCase() : ''}}</td>
      </tr>
      <tr>
        <td colspan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Instruct to take Corrective Action</td>
        <td>
          <vue-editor  class="my-2" v-if="editNonConformity" :editor-toolbar="toolbar" v-model="nonConformityField.corrective_action.instruction"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.corrective_action.instruction"/>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Attached any other instruction</td>
        <td>
          <template v-if="editNonConformity">
            <template v-if="!addRsncInstructionAttachment">
              <div class="mb-2">
                <button class="e-btn e-btn-green mr-3"
                        @click="addRsncInstructionAttachment=!addRsncInstructionAttachment"
                >
                  Add Attachment
                </button>
                <button class="e-btn e-btn-blue">
                  Download All
                </button>
              </div>
            </template>
            <div v-else>
              <input type="file" class="w-75" @change="addRsncCorrectiveActionAttachments(3,'addRsncInstructionAttachment')" ref="rsncInstructionAttachment"  multiple>
              <button class="e-btn e-btn-outlined-red">Cancel</button>
            </div>
          </template>
          <rs-attachment v-if="rsncInstructionAttachments.length" :attachments="rsncInstructionAttachments"
                         style="height: 15vh;"
                         :type="3"
                         view-permission="psc.main.deficiency.overview.attachments-view-download"
                         download-permssion="psc.main.deficiency.overview.attachments-view-download"
                         delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
                         @deleteEmitter="deleteCorrectiveAttachment"
          >
          </rs-attachment>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td>Date of Instruction</td>
        <td>
          <input v-if="editNonConformity" type="date" class="form-control form-control-sm" v-model="nonConformityField.corrective_action.instruction_date">
          <span v-else>{{nonConformity.corrective_action.instruction_date | eliteDate}}</span>
        </td>
      </tr>
      <tr>
        <td>To be Done</td>
        <td>
          <input v-if="editNonConformity" type="date" class="form-control form-control-sm" v-model="nonConformityField.corrective_action.instruction_done_date">
          <span v-else>{{nonConformity.corrective_action.instruction_done_date | eliteDate}}</span>
        </td>
      </tr>
      <tr>
        <td>Instructed by (Person In Charge)</td>
        <td></td>
      </tr>
      <tr>
        <td colspan="2">
          <hr />
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Action Taken</td>
        <td>
          <vue-editor  class="my-2" v-if="editNonConformity" :editor-toolbar="toolbar" v-model="nonConformityField.corrective_action.action_taken"></vue-editor>
          <div v-else class="pre-wrap-txt textarea-display-card my-2" v-html="nonConformity.corrective_action.action_taken"/>
        </td>
      </tr>
      <tr>
        <td>Date of Report</td>
        <td>
          <input v-if="editNonConformity" type="date" class="form-control form-control-sm" v-model="nonConformityField.corrective_action.action_taken_date" >
          <span v-else>{{nonConformity.corrective_action.action_taken_date | eliteDate}}</span>
        </td>
      </tr>
      <tr>
        <td>Reported by</td>
        <td>
          <input v-if="editNonConformity" type="text" class="form-control form-control-sm" v-model="nonConformityField.corrective_action.reported_by">
          <span v-else>{{nonConformity.corrective_action.reported_by}}</span>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Attached rectified photos</td>
        <td>
          <template v-if="editNonConformity">
            <template v-if="!addRsncRectifiedPhoto">
              <div class="mb-2">
                <button class="e-btn e-btn-green mr-3"
                        @click="addRsncRectifiedPhoto=!addRsncRectifiedPhoto"
                >
                  Add Attachment
                </button>
                <button class="e-btn e-btn-blue">
                  Download All
                </button>
              </div>
            </template>
            <div v-else>
              <input type="file" class="w-75" @change="addRsncCorrectiveActionAttachments(4,'addRsncRectifiedPhoto')" accept="image/*" ref="rsncRectifiedPhoto"  multiple>
              <button class="e-btn e-btn-outlined-red">Cancel</button>
            </div>
          </template>
          <div class="row">
            <div   v-if="rsncRectifiedPhotoAttachments.length" class="col-lg-2 col-md-4 col-sm-12 mb-1 justify-content-center align-content-center"
                   v-for="attachment in rsncRectifiedPhotoAttachments">
              <rs-image-component :image-info="attachment"
                                  :type="4"
                                  @deleteEmitter="deleteCorrectiveAttachment"
                                  view-permission="psc.main.deficiency.overview.attachments-view-download"
                                  download-permssion="psc.main.deficiency.overview.attachments-view-download"
                                  delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
              >
              </rs-image-component>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <hr>
        </td>
      </tr>
      <tr>
        <td style="width: 25%">Attached rectified document/report</td>
        <td>
          <template v-if="editNonConformity">
              <template v-if="!addRsncRectifiedAttachments">
                <div class="mb-2">
                  <button class="e-btn e-btn-green mr-3"
                          @click="addRsncRectifiedAttachments=!addRsncRectifiedAttachments"
                  >
                    Add Attachment
                  </button>
                  <button class="e-btn e-btn-blue">
                    Download All
                  </button>
                </div>
              </template>
              <div v-else>
                <input type="file" class="w-75" @change="addRsncCorrectiveActionAttachments(5,'addRsncRectifiedAttachments')" ref="rsncRectifiedAttachments"  multiple>
                <button class="e-btn e-btn-outlined-red">Cancel</button>
              </div>
            </template>
          <rs-attachment v-if="rsncRectifiedAttachments.length" :attachments="rsncRectifiedAttachments"
                           style="height: 15vh;"
                           :type="5"
                           view-permission="psc.main.deficiency.overview.attachments-view-download"
                           download-permssion="psc.main.deficiency.overview.attachments-view-download"
                           delete-permission="psc.main.deficiency.overview.ca.attachments-delete"
                           @deleteEmitter="deleteCorrectiveAttachment"
            >
            </rs-attachment>
        </td>
      </tr>
    </table>
    <hr />
    <table>
      <tr>
        <td colspan="2">
          Corrective action is effective to keep suitable level appropriate to the risk of problem encountered. (Case is completed and closed.)
        </td>
        <td colspan="2">
          <select v-if="editNonConformity" class="form-control form-control-sm mb-1" style="width: 150px;" @change="setCorrectiveActionOption" v-model="nonConformityField.corrective_action.is_corrective_action_effective">
            <option :value="1">Yes</option>
            <option :value="0">No</option>
          </select>
          <span v-else>{{nonConformity.corrective_action.is_corrective_action_effective ? 'YES' : 'NO'}}</span>
        </td>
      </tr>
      <tr v-if="nonConformity.corrective_action.is_corrective_action_effective">
        <td style="width: 25%">
          a) Verification of effectiveness for Corrective Action
        </td>
        <td style="width: 25%">
          <select v-if="editNonConformity" class="form-control form-control-sm mb-1" style="width: 150px;" v-model="nonConformityField.corrective_action.is_corrective_action_verified">
            <option :value="1">Yes</option>
            <option :value="0">No</option>
          </select>
          <span v-else>{{nonConformity.corrective_action.is_corrective_action_verified ? 'YES' : 'NO'}}</span>
        </td>
        <td style="width: 10%">Date of verification</td>
        <td>
          <input v-if="editNonConformity" type="date" class="form-control-sm form-control" style="width: 150px;" v-model="nonConformityField.corrective_action.is_corrective_action_verified_date">
          <span v-else>{{nonConformity.corrective_action.is_corrective_action_verified_date | eliteDate }}</span>
        </td>
      </tr>
      <tr v-if="nonConformity.corrective_action.is_corrective_action_effective">
        <td>
          b) Effectiveness for Corrective Actions has not been confirmed,
        </td>
        <td>
          <select v-if="editNonConformity" class="form-control form-control-sm mb-1" style="width: 150px;" v-model="nonConformityField.corrective_action.is_corrective_action_confirmed">
            <option :value="1">Yes</option>
            <option :value="0">No</option>
          </select>
          <span v-else>{{nonConformity.corrective_action.is_corrective_action_confirmed ? 'YES' : 'NO'}}</span>
        </td>
        <td>Date of verification</td>
        <td>
          <input v-if="editNonConformity" type="date" class="form-control-sm form-control" style="width: 150px;" v-model="nonConformityField.corrective_action.is_corrective_action_confirmed_date">
          <span v-else>{{nonConformity.corrective_action.is_corrective_action_confirmed_date | eliteDate}}</span>
        </td>
      </tr>
      <tr>
        <td>P.I.C. or Superintendent</td>
        <td></td>
      </tr>
    </table>
  </form>
</template>

<style scoped>
table td {
  padding: 2px !important;
}
</style>
